import { render } from "./index.vue?vue&type=template&id=64882cdf&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=64882cdf&scoped=true&lang=css"
import "./index.vue?vue&type=style&index=1&id=64882cdf&scoped=true&lang=css"
import "./index.vue?vue&type=style&index=2&id=64882cdf&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-64882cdf"

export default script